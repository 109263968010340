var NODE = 1;
var initialSlide = 0; //设置默认索引为0

var obj = {
  index: function () {
    function getRequest() {
      var query = window.location.search;
      if (query.indexOf("?") != -1) {
        var str = query.substring(1);
        var strs = str.split("=");
        if (strs[1] == 1) {
          initialSlide = 1;
        } else if (strs[1] == 2) {
          initialSlide = 2;
        } else if (strs[1] == 3) {
          initialSlide = 3;
        } else if (strs[1] == 4) {
          initialSlide = 4;
        } else if (strs[1] == 5) {
          initialSlide = 5;
        } else if (strs[1] == 6) {
          initialSlide = 6;
        }
      }
    }
    getRequest(); //二级页面导航栏跳转
    this.indexBind(); // 绑定
    this.sceneParallax(); //首屏景深
    this.newsBanner(); //新闻轮播
    // this.infinite(); // 移动端响应
    this.loading(); // loading
    this.indexAjax(); // 首页数据
    this.downloadAjax(); //下载接口
    this.jqslideTxtBox(); // 游戏资讯信息切换
    this.swiper(); //swiper
    this.countNum(); //阿里云埋点
  },
  sceneParallax: function () {
    var scene = document.getElementById("scene");
    var parallax = new Parallax(scene);
  },
  // 新闻列表
  newsList: function () {
    this.loading(); // loading
    this.newsTabPage(); // 新闻导航切换
    this.newsZxList(); // 新闻最新列表
  },
  // 新闻详情
  newsDetails: function () {
    this.newsInfo();
    this.loading(); // loading
  },
  // 视听回廊
  galleryList: function () {
    this.loading(); // loadin
    // this.jqslideTxtBox(); // 游戏咨询信息切换
    // this.videoSwiper(); //视频轮播
    this.wookmarkBind(); // 瀑布流图片
    this.indexBind(); // 绑定
    // this.galleryBind(); //按钮事件
    // this.galleryAjax(); //视听回廊接口
  },
  // 游戏资讯切换
  jqslideTxtBox: function () {
    jQuery(".news_box").slide({
      effect: "left",
      trigger: "click",
      easing: "swing",
    });
  },
  // 公告、活动、攻略跳转
  newsTabPage: function () {
    $(".tab_list").on("click", "div", function (event) {
      var cate_id = $(this).attr("cate-id");
      window.location.href =
        window.location.href.split("?")[0] + "?cate_id=" + cate_id + "&page=1";
    });
  },
  loading: function () {
    var index = layer.load(2, { shade: [0.1, "#fff"] });
    setTimeout(function () {
      layer.close(index);
      $(".index").removeClass("loading");
    }, 200);

    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  // 悬浮窗口动效
  floatMove: function (status) {
    if (status) {
      $(".float-code").removeClass("float-code-ani");
      $(".float_bg").css("display", "block");
    } else {
      $(".float-code").addClass("float-code-ani");
      $(".float_bg").fadeOut();
    }
  },
  // 首页接口请求
  indexAjax: function () {
    // var index = layer.load(2, { shade: [0.1, '#fff'] });
    var _this = this;
    $.ajax({
      url: "//api.qingcigame.com/web/travel/index",
      // url: "//api.qingcigame.com/web/cherry/index",
      type: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          var gonggao = json.data.articleList.gonggao; //公告
          // var gonggao = json.data.articleList.gongyi; //公告
          var activity = json.data.articleList.activity_news; //活动
          var gonglue = json.data.articleList.gonglv; //攻略
          var role = json.data.role_info; //时光大世界角色

          // 按钮链接
          var appoint_url = "https://qcg.ink/WdkWA3"; //预约活动
          var heike_url = "https://qcg.ink/ILXF9"; //黑客活动

          var pay_url = "https://pay.qingcigame.com/home"; //充值
          var group_url =
            "https://static2.qcplay.com/qq_group_jump/index.html?game_id=35"; //加群
          var taptap_url =
            "https://tap.cn/Jh0RpEBY?channel=rep-rep_o9mfjrceh9d"; //TapTap

          // 模拟器
          var leidian_url =
            "https://lddl01.ldmnq.com/downloader/ldplayerinst9.exe?n=LDplayer9_com.qcplay.timetravel.zsf_402166_ld.exe"; //雷电
          var xiaoyao_url =
            "https://download.microvirt.com/download/game/XYAZ-Installer-pncom.qcplay.timetravel.zsf-cha857e322.exe"; //逍遥
          var mumu_url =
            "https://a11.gdl.netease.com/MuMuInstaller_3.1.6.0_yx-sglxs_zh-Hans_1703043456.exe"; //mumu

          $(".appoint-btn").attr("href", appoint_url);
          $(".taptap-btn").attr("href", taptap_url);
          $(".group-btn").attr("href", group_url);
          $(".top-up-btn").attr("href", pay_url);

          $(".leidian-btn").attr("href", leidian_url);
          $(".xiaoyao-btn").attr("href", xiaoyao_url);
          $(".mumu-btn").attr("href", mumu_url);

          // 游戏福利
          $(".welfare_01").attr("href", appoint_url);
          $(".welfare_02").attr("href", heike_url);

          // 【时光资讯】公告
          $.each(gonggao, function (index, values) {
            var gonggaoHtml = `<li class="news_item">
                            <a href="news-details.html?id=${values.id}">
                            <h2 class="item_info">${values.article_title}</h2>
                            <p class="time">${values.created_at}</p>
                            </a>
                        </li>`;
            $(".notice").append(gonggaoHtml);
          });

          // 【时光资讯】活动
          $.each(activity, function (index, values) {
            var activityHtml = `<li class="news_item">
                            <a href="news-details.html?id=${values.id}">
                            <h2 class="item_info">${values.article_title}</h2>
                            <p class="time">${values.created_at}</p>
                            </a>
                        </li>`;
            $(".activity").append(activityHtml);
          });

          // 【时光资讯】最新
          $.each(gonglue, function (index, values) {
            var gonglueHtml = `<li class="news_item">
                            <a href="news-details.html?id=${values.id}">
                            <h2 class="item_info">${values.article_title}</h2>
                            <p class="time">${values.created_at}</p>
                            </a>
                        </li>`;
            $(".strategy").append(gonglueHtml);
          });

          // 新闻hover动效
          $(".news_list .news_item").on("hover", function () {
            $(this).addClass("selected").siblings().removeClass("selected");
          });

          // 限制字数
          // _this.Textlength(20);

          //时光大世界数据
          // 第一世界 埃拉西亚
          $.each(role[1], function (index, values) {
            var roleHtml = `<li class="icon_item" quality=${values.quality} race=${values.race} cost=${values.cost} name=${values.name}>
                        <img src="https://download4.qcplay.com/travel/travel_info_pic/${values.id}.png" alt="">
                        <div class="retinue-name">${values.name}</div>
                    </li>`;
            $(".world_box .role-w1")
              .append(roleHtml)
              .find("li")
              .eq(0)
              .addClass("select");
          });

          // 第一世界 九州仙界
          $.each(role[4], function (index, values) {
            var roleHtml = `<li class="icon_item" quality=${values.quality} race=${values.race} cost=${values.cost} name=${values.name}>
                        <img src="https://download4.qcplay.com/travel/travel_info_pic/${values.id}.png" alt="">
                        <div class="retinue-name">${values.name}</div>
                    </li>`;
            $(".world_box .role-w2")
              .append(roleHtml)
              .find("li")
              .eq(0)
              .addClass("select");
          });

          // 第三世界 大荒异界
          $.each(role[2], function (index, values) {
            var roleHtml = `<li class="icon_item" quality=${values.quality} race=${values.race} cost=${values.cost} name=${values.name}>
                        <img src="https://download4.qcplay.com/travel/travel_info_pic/${values.id}.png" alt="">
                        <div class="retinue-name">${values.name}</div>
                    </li>`;
            $(".world_box .role-w3")
              .append(roleHtml)
              .find("li")
              .eq(0)
              .addClass("select");
          });

          // 第四世界 赛博世界
          $.each(role[3], function (index, values) {
            var roleHtml = `<li class="icon_item" quality=${values.quality} race=${values.race} cost=${values.cost} name=${values.name}>
                        <img src="https://download4.qcplay.com/travel/travel_info_pic/${values.id}.png" alt="">
                        <div class="retinue-name">${values.name}</div>
                    </li>`;
            $(".world_box .role-w4")
              .append(roleHtml)
              .find("li")
              .eq(0)
              .addClass("select");
          });

          // 随从click切换
          $(".icon_list li").on("click", function () {
            var name = $(this).attr("name"); //品质
            var quality = $(this).attr("quality"); //品质
            var race = $(this).attr("race"); //种族
            var cost = $(this).attr("cost"); //费用
            $(this).addClass("select").siblings().removeClass("select");

            // $('.icon_intro').fadeOut();

            $(".quality span").html(quality);
            $(".race span").html(race);
            $(".cost span").html(cost);
            $(".icon_intro .name").html(name);
            // $('.icon_intro').fadeIn();
          });

          // 世界tab切换
          $(".list li").on("mouseenter", function () {
            let index = $(this).index() + 1;
            NODE = index;
            change(index);

            var _this = $(`.role-w${index}`).find(".select");
            var name = _this.attr("name"); //品质
            var quality = _this.attr("quality"); //品质
            var race = _this.attr("race"); //种族
            var cost = _this.attr("cost"); //费用

            setTimeout(function () {
              $(".icon_list").hide();
              $(`.role-w${index}`).show();
              $(".quality span").html(quality);
              $(".race span").html(race);
              $(".cost span").html(cost);
              $(".icon_intro .name").html(name);
            }, 250);
          });

          // 切换方法
          function change(index, status) {
            let _this = $("#w" + index + "");

            var role_world = $(`.role-w${index}`).find(".select");
            var name = role_world.attr("name"); //品质
            var quality = role_world.attr("quality"); //品质
            var race = role_world.attr("race"); //种族
            var cost = role_world.attr("cost"); //费用

            if (
              !_this.hasClass("act") &&
              !_this.parent().hasClass("buffering")
            ) {
              _this.parent().addClass("buffering");
              _this.addClass("act").siblings().removeClass("act");
              // 角色&属性板切换
              $(".right_part").addClass("fadeRight");
              $(".left_part").addClass("fadeLft");
              setTimeout(() => {
                if (status == "left") {
                  $(".attr_box").attr("class", "attr_box w4");
                } else if (status == "right") {
                  $(".attr_box").attr("class", "attr_box w1");
                } else {
                  $(".attr_box").attr("class", "attr_box w" + index);
                }
              }, 250);
              setTimeout(() => {
                $(".right_part").removeClass("fadeRight");
                $(".left_part").removeClass("fadeLft");
                _this.parent().removeClass("buffering");
              }, 500);
              setTimeout(function () {
                $(".icon_list").hide();
                $(`.role-w${index}`).show();
                $(".quality span").html(quality);
                $(".race span").html(race);
                $(".cost span").html(cost);
                $(".icon_intro .name").html(name);
              }, 250);
            }
          }
          // 世界向后切换
          $(".left_arrow").on("click", function () {
            let a = !$(".list").hasClass("buffering");
            if (NODE == 1 && a) {
              NODE = 4;
              change(NODE, "left");
            } else if (a) {
              NODE -= 1;
              change(NODE);
            }
          });

          // 世界向前切换
          $(".right_arrow").on("click", function () {
            let a = !$(".list").hasClass("buffering");
            if (NODE == 4 && a) {
              NODE = 1;
              change(NODE, "right");
            } else if (a) {
              NODE += 1;
              change(NODE);
            }
          });

          $(".content").on("mouseenter", function () {
            $(this).children().fadeIn();
            $(this).addClass("aniStop");
          });
          $(".content").on("mouseleave", function () {
            $(this).children().fadeOut();
            $(this).removeClass("aniStop");
          });
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        // layer.close(index);
      },
    });
  },
  // 内容字体长度超过40，标题字体长度超过25
  Textlength: function (x) {
    $(".content-02 .bd h2").each(function () {
      if ($(this).text().length > x) {
        var str = $(this).text();
        $(this).text(str.substr(0, x) + "...");
      }
    });
  },
  // 下载接口请求
  downloadAjax: function () {
    $(".download-btn").on("click", function () {
      var className = $(this).attr("class");
      $.ajax({
        url: "//api.qingcigame.com/get_url?game_id=35",
        type: "GET",
        xhrFields: {
          withCredentials: true,
        },
        success: function (json) {
          if (json.code == 200) {
            // var and_url = json.data.android_down_url; //安卓
            // var ios_url = json.data.ios_down_url; //ios

            $(".and-btn").attr("data-event", "and-download");
            $(".ios-btn").attr("data-event", "ios-download");

            if (className.indexOf("ios-btn") !== -1) {
              // ios
              location.href = json.data.ios_down_url;
            } else {
              // 安卓
              location.href = json.data.android_down_url;
            }
          }
        },
        error: function () {
          layer.msg("服务器请求失败", { time: 2000 });
        },
        complete: function () {
          // layer.close(index);
        },
      });
    });
  },
  // 新闻绑定相关
  newsBind: function () {
    // 资讯hover动效
    $(".tab_list .tab_item").on("click", function () {
      $(this).addClass("on").siblings().removeClass("on");
    });
  },
  newsBanner: function () {
    // index = layer.load(2, { shade: [0.1, '#fff'] });
    $.ajax({
      url: "https://api.qingcigame.com/web/news/info",
      type: "GET",
      data: {
        game_id: 35,
      },
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          var article = json.data.article;
          $.each(article.game_information, function (index, values) {
            var gameInfoIndex = `<div class="swiper-slide banner">
                            <a href="${values.jump_url}">
                            <img  class="banner_img" src="${values.image}">
                            </a>
                            <div class="banner_title">${values.name}</div>
                        </div>`;
            $(".news-swiper .swiper-wrapper").append(gameInfoIndex);
          });

          newsSwiper();
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        // layer.close(index);
      },
    });

    function newsSwiper() {
      var mySwiperNews = new Swiper(".news-swiper", {
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        autoplay: 2000,
        autoplayDisableOnInteraction: false,
        // grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
      });
    }
  },
  // 首页绑定相关
  indexBind: function () {
    $(".monitor-btn").on("click", function () {
      var mon_btn = $(this).attr("data-event");
      obj.countNum(mon_btn);
    });

    // 视频播放
    var Media = document.getElementById("media-play");
    $(".video-btn").click(function () {
      // layer.msg("敬请期待~", { time: 2000 });
      var _this = $(this);
      var data = _this.attr("data");
      $(".play-popup").fadeIn().find("video").attr("src", data);
      Media.play();
    });

    // 关闭视频
    $(".video-pop").click(function (event) {
      $(".popup").fadeOut().find("video").attr("src", "");
      Media.pause();
    });

    // $(".welfare_02").click(function () {
    //   layer.msg("敬请期待~", { time: 2000 });
    // });

    // 资讯click动效
    $(".tab_list .tab_item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");
      $(this).removeClass("ani-btn").siblings().addClass("ani-btn");
    });

    // 时光画廊click动效
    // $(".gallery_list .tab_item").on('click', function() {
    //     $(this).addClass("active").siblings().removeClass("active");
    //     $(this).removeClass("ani-btn").siblings().addClass("ani-btn");
    // });

    // 同人专区暂未开放
    $(".gallery_list #fan-zone").on("click", function () {
      layer.msg("敬请期待~", { time: 2000 });
    });

    // $(".tab_list .tab_item").on('hover', function() {
    //   $(this).addClass("active").siblings().removeClass("active")
    // })

    // 关闭弹窗
    $(".popup").on("click", ".close-btn", function (event) {
      $(".popup").fadeOut();
    });

    // 关闭弹窗
    $(".popup").on("click", function (event) {
      $(".popup").fadeOut();
    });

    // 适龄弹窗
    $(".year_box").click(function (event) {
      $(".year_pop").fadeIn();
    });

    // 模拟器弹窗
    $(".simulator-box").click(function (event) {
      $(".simulator-wrap").fadeIn();
    });

    // taptap关注弹窗
    $(".taptap-gz-btn").click(function (event) {
      $(".taptap-gz-popup").fadeIn();
    });

    // 微信群关注弹窗
    $(".wx-group-gz-btn").click(function (event) {
      $(".wx-group-gz-popup").fadeIn();
    });

    // 微信公众号关注弹窗
    $(".wx-gzh-gz-btn").click(function (event) {
      $(".wx-gzh-gz-popup").fadeIn();
    });

    // qq群关注弹窗
    $(".qq-group-gz-btn").click(function (event) {
      $(".qq-group-gz-popup").fadeIn();
    });

    // 二维码按钮
    $(".changes-btn").click(function (event) {
      // $('.footer').removeClass('footer-on');
      if ($(".float-code").hasClass("float-code-ani")) {
        obj.floatMove(true);
      } else {
        obj.floatMove(false);
      }
    });

    // 关闭弹窗
    $(".popup").on("click", ".close-btn", function (event) {
      $(".popup").fadeOut();
    });

    // 时光画廊导航栏切换
    $(".gallery-fan").on("click", function () {
      layer.msg("敬请期待~", { time: 2000 });
      // $('.gallery-nav-box').addClass('active');
      // $('.official-zone').fadeOut();
      // $('.fan-zone').fadeIn();
    });

    $(".gallery-official").on("click", function () {
      $(".gallery-nav-box").removeClass("active");
      $(".fan-zone").fadeOut();
      $(".official-zone").fadeIn();
    });

    // 时光画廊【壁纸】
    $(".grid-item img").on("click", function () {
      var _this = $(this);
      var img_url = _this.attr("src");
      $(".wallpaper-wrap").fadeIn();
      $(".wallpaper-wrap img").attr("src", img_url);
    });

    // 左侧大图特殊处理
    $(".big-grid-item").on("click", function () {
      var _this = $(".big-grid-item img");
      var img_url = _this.attr("src");
      $(".wallpaper-wrap").fadeIn();
      $(".wallpaper-wrap img").attr("src", img_url);
    });

    $(".wallpaper-wrap").on("click", ".close-btn", function (event) {
      $(".popup").fadeOut();
      setTimeout(() => {
        $(".wallpaper-wrap img").attr("src", "");
      }, 300);
    });
  },
  // infinite: function() {
  //     if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
  //         var htmlWidth = $('html').width();
  //         if (htmlWidth >= 750) {
  //             $("html").css({
  //                 "font-size": "40px"
  //             });
  //         } else {
  //             $("html").css({
  //                 "font-size": 40 / 750 * htmlWidth + "px"
  //             });
  //         }
  //     }
  // },

  swiper: function () {
    var swiperH = new Swiper(".swiper-container-h", {
      direction: "vertical",
      resistanceRatio: 0,
      mousewheelControl: true,
      spaceBetween: 0,
      initialSlide: initialSlide,
      // speed: 200,
      slidesPerView: "auto",
      onInit: function (swiper) {
        //Swiper2.x的初始化是onFirstInit
        swiperAnimateCache(swiper); //隐藏动画元素
        swiperAnimate(swiper); //初始化完成开始动画
        function Ani(btn, page) {
          $(btn).on("click", function () {
            swiperH.slideTo(page, 500, true);
            $(".nav-link-0" + page + "")
              .addClass("active")
              .siblings()
              .removeClass("active");
          });
        }

        function navLink() {
          for (var i = 5; i >= 0; i--) {
            Ani(".nav-link-0" + i + " .link-a", i);
          }
        }
        navLink();

        var aniLink01 = Ani(".arrow_down", "1");
      },
      onSlideChangeStart: function (swiper) {
        swiperAnimate(swiper);
        // $('.footer').removeClass('footer-on');
        if (swiper.activeIndex == 0) {
          obj.floatMove(false);
        } else if (swiper.activeIndex == 2) {
          obj.floatMove(false);
        } else {
          obj.floatMove(true);
        }

        let index = swiper.realIndex;
        // console.log(index)
        $(".nav-link-0" + index + "")
          .addClass("active")
          .siblings()
          .removeClass("active");
      },
      onSlideChangeEnd: function (swiper) {
        swiperAnimate(swiper); //每个slide切换结束时也运行当前slide动画
      },
      onTouchEnd: function (swiper) {
        swiperAnimate(swiper);
      },
    });

    // 游戏特色
    var cardSwiper = new Swiper(".swiper-container-feature", {
      direction: "horizontal",
      loop: true,
      pagination: ".swiper-pagination",
      prevButton: ".swiper-button-prev",
      nextButton: ".swiper-button-next",
      autoplayDisableOnInteraction: false,
      paginationClickable: true,
      effect: "coverflow",
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: "auto",
      loopedSlides: 3,
      // 横版（暂时不用）
      // coverflow: {
      //     rotate: 0, // 旋转的角度
      //     stretch: 200, // 拉伸   图片间左右的间距和密集度
      //     depth: 400, // 深度   切换图片间上下的间距和密集度
      //     slideShadows: false, // 页面阴影效果
      // },
      // 竖版
      coverflow: {
        rotate: 0, // 旋转的角度
        stretch: -50, // 拉伸   图片间左右的间距和密集度
        depth: 150, // 深度   切换图片间上下的间距和密集度
        // modifier: 10, // 修正值 该值越大前面的效果越明显
        slideShadows: false, // 页面阴影效果
      },
    });
  },
  analysisParams: function (url) {
    const params = {};
    const paramsString = url.split("?")[1];
    if (!paramsString) {
      return params;
    }
    const str = paramsString.split("&");
    for (let idx = 0; idx < str.length; idx++) {
      const values = str[idx].split("=");
      params[values[0]] = values[1];
    }
    return params;
  },
  // 新闻最新接口
  newsZxList: function () {
    const params = obj.analysisParams(window.location.href);
    console.log(params);
    var cate_id, page, current, index;
    cate_id = params.cate_id;
    page = params.page;
    current = window.location.href.split("?")[0];

    index = layer.load(2, { shade: [0.1, "#fff"] });
    $(".news-list .tab_list .a-tab-" + cate_id + "")
      .addClass("on")
      .siblings("div")
      .removeClass("on");

    $.ajax({
      // url: api + "/article",
      url: "https://api.qingcigame.com/web/article",
      type: "GET",
      data: {
        game_id: 35,
        cate_id: cate_id,
        page: page,
      },
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          var current_page = json.data.articleList.current_page; // 当前页数
          var last_page = json.data.articleList.last_page; // 总共页数
          var prev_page_url = json.data.articleList.prev_page_url; // 前一页
          var next_page_url = json.data.articleList.next_page_url; // 后一页
          var _data = json.data.articleList.data;
          if (_data.length == 0) {
            $(".news-list").addClass("list-null");
          } else {
            $(".news-list").removeClass("list-null");
            // 渲染列表
            // $.each(_data, function(index, values) {
            //     var dtHtml = '<li> class="news_item"' +
            //         '<a title="' + values.article_title + '" href="news-details.html?id=' + values.id + '">' +
            //         '<p class="lside">' +
            //         '<span class="time">' + values.created_at + '</span>' +
            //         '<span class="title" style="display: block;">' + values.article_title + '</span>' +
            //         '</p>' +
            //         '</a>' +
            //         '</li>';
            //     $('.new-list ul').append(dtHtml);
            // });
            $.each(_data, function (index, values) {
              var dtHtml = ` <li class="news_item">
                            <a title="${values.article_title}" href="news-details.html?id=${values.id}">
                                <div class="news_title">·${values.article_title}</div>
                                <div class="news_info">
                                    <div class="content">${values.article_excerpt}</div>
                                    <div class="time">${values.created_at}</div>
                                </div>
                            </a>
                        </li>`;
              $(".news_list").append(dtHtml);
            });
            $(".new-list").addClass("new-list-show");
            $(".current_page").html(current_page); // 当前页数
            $(".last_page").html(last_page); // 总共页数
            if (prev_page_url === null) {
              // 上一页
              $(".front-link").hide(); // 没有第一页
            } else {
              $(".front-link").attr(
                "href",
                current +
                  "?cate_id=" +
                  cate_id +
                  "&page=" +
                  prev_page_url.split("?page=")[1]
              );
            }
            if (next_page_url === null) {
              // 下一页
              $(".after-link").hide(); // 没有最后一页
            } else {
              $(".after-link").attr(
                "href",
                current +
                  "?cate_id=" +
                  cate_id +
                  "&page=" +
                  next_page_url.split("?page=")[1]
              );
            }
            $(".new-list").addClass("new-list-show");
          }
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        layer.close(index);
      },
    });
  },
  // 新闻详情
  newsInfo: function () {
    var id = obj.getQueryVariable("id");
    var index = layer.load(2, { shade: [0.1, "#fff"] });
    $.ajax({
      // url: api + "/article/" + id + "",
      url: "https://api.qingcigame.com/web/article/" + id + "",
      type: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        $(".news-det .main").addClass("main-show");
        if (json.code == 200) {
          var newsTitle = json.data.list.article_title; // 标题
          var detailsInfo = json.data.list.article_content; // 内容
          var origin = json.data.list.origin; // 作者
          var created_at = json.data.list.created_at; // 时间
          $(".news .news-title h3, .current-title").html(newsTitle);

          // 作者
          origin
            ? $(".origin").html(origin)
            : $(".origin").html("时光旅行社官方");
          $(".created_at").html(created_at);

          $(".details-info").append(detailsInfo);

          // 翻页功能
          obj.pageDown(json.data.last, json.data.next, "id");

          $("title").html(newsTitle);
          $('meta[name="keywords"]').attr(
            "content",
            json.data.list.article_keywords
          );
          $('meta[name="description"]').attr(
            "content",
            json.data.list.article_excerpt
          );
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        layer.close(index);
      },
    });
  },
  // 翻页功能
  pageDown: function (lastPage, nextPage, cite) {
    var href = window.location.href.split("?")[0] + "?" + cite + "=";
    if (lastPage !== "")
      $(".front-link")
        .addClass("show")
        .attr("href", href + lastPage.id)
        .attr("title", lastPage.article_title);
    if (nextPage !== "")
      $(".after-link")
        .addClass("show")
        .attr("href", href + nextPage.id)
        .attr("title", nextPage.article_title);
  },
  wookmarkBind: function () {
    var articleList = [
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/1.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/3.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/5.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/4.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/7.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/6.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/10.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/2.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/8.png",
      },
      {
        thumbnail:
          "https://download4.qcplay.com/sglxs/official/publicize4/9.png",
      },
    ];
    $.each(articleList, function (index, values) {
      var dtHtml =
        '<li class="grid-item">' +
        "<img src=" +
        values.thumbnail +
        ">" +
        "</li>";
      $("#tiles").append(dtHtml);
    });

    $("#tiles").imagesLoaded(function () {
      var options = {
        itemWidth: 200,
        autoResize: true,
        container: $("#tiles"),
        offset: 15,
        outerOffset: 0,
        flexibleWidth: 285,
      };
      var handler = $("#tiles li");
      handler.wookmark(options);
    });

    setTimeout(() => {
      $(".htmleaf-box").addClass("htmleaf-box-show");
    }, 1000);
  },

  // 获取当前hash的具体参数
  getQueryVariable: function (variable) {
    var query = window.location.href.split("?")[1];
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  },
  // 阿里云埋点
  countNum: function (data) {
    setTimeout(() => {
      try {
        __bl.sum(data);
      } catch (e) {
        console.log(e);
      }
    }, 500);
  },
};
